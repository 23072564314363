/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Image, PriceWrap, Title, Fullmap, FullmapWrap, FullmapCover, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"M&M"} description={"Chata Kokava Línia"}>
        <Column className="--menu pb--08 pt--08" menu={true} name={"qayq2nj9vjo"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"M&amp;M Chata Kokava Línia "}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/"} target={""} content={"I-Agro"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#d4r92h5wlf"} target={""} content={"Izby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#y5pupgl9tb"} target={""} content={"Vybavenie"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#gva6130ffzh"} target={""} content={"Cenník"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#4wmdkj212gf"} target={""} content={"Rezervácia"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#cjjwpodf3bu"} target={""} content={"Kontakt"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/m-and-m#gyeg8tk1s7n"} target={""} content={"Okolie"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-1qxm2g7 pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17777/8380bb6d9cfe43bdb63bd9333b1ee166_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"<br>"}>
              </Text>

              <Image className="--right mt--40" src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"<font color=\"rgba(130,61,71,1)\"><br></font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"d4r92h5wlf"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":604}} srcSet={"https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/6568e1873ca8484b8a50105583623282_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17777/e97afa65bf06402e9af397c8b9e1d14e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/e97afa65bf06402e9af397c8b9e1d14e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/e97afa65bf06402e9af397c8b9e1d14e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/e97afa65bf06402e9af397c8b9e1d14e_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":612}} srcSet={"https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/bee885af13434c559f9793db4a3cd061_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/de30348b515e49a2bd52cb81ea704864_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":601}} srcSet={"https://cdn.swbpg.com/t/17777/de30348b515e49a2bd52cb81ea704864_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/de30348b515e49a2bd52cb81ea704864_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/de30348b515e49a2bd52cb81ea704864_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/c162dd6d43c6453f96a5bab38f3f20ae_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/c162dd6d43c6453f96a5bab38f3f20ae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/c162dd6d43c6453f96a5bab38f3f20ae_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/c162dd6d43c6453f96a5bab38f3f20ae_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/c162dd6d43c6453f96a5bab38f3f20ae_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17777/1e268c518ca74e70802462bc3bbb2f18_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17777/1e268c518ca74e70802462bc3bbb2f18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/1e268c518ca74e70802462bc3bbb2f18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/1e268c518ca74e70802462bc3bbb2f18_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":532}} srcSet={"https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17777/5f056daaa9e048588efd922fb770f7df_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/db66feebaf3742e7a7ad50e6c3d14322_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":522}} srcSet={"https://cdn.swbpg.com/t/17777/db66feebaf3742e7a7ad50e6c3d14322_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/db66feebaf3742e7a7ad50e6c3d14322_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/db66feebaf3742e7a7ad50e6c3d14322_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"y5pupgl9tb"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/2d8f57477056450b847c9ee36a72b108_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":641}} srcSet={"https://cdn.swbpg.com/t/17777/2d8f57477056450b847c9ee36a72b108_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/286407e2176940ea93e622a6c4fd6286_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/17777/286407e2176940ea93e622a6c4fd6286_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/286407e2176940ea93e622a6c4fd6286_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/286407e2176940ea93e622a6c4fd6286_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/286407e2176940ea93e622a6c4fd6286_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/852b222f139e469086dadc209d36d707_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/17777/852b222f139e469086dadc209d36d707_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/852b222f139e469086dadc209d36d707_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/852b222f139e469086dadc209d36d707_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/852b222f139e469086dadc209d36d707_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/95791501771f41498770dcacbdec47c3_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/17777/95791501771f41498770dcacbdec47c3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/95791501771f41498770dcacbdec47c3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/95791501771f41498770dcacbdec47c3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/95791501771f41498770dcacbdec47c3_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/3f3e9be2b7554d1baf6f9e49d215224a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":649}} srcSet={"https://cdn.swbpg.com/t/17777/3f3e9be2b7554d1baf6f9e49d215224a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/3f3e9be2b7554d1baf6f9e49d215224a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/3f3e9be2b7554d1baf6f9e49d215224a_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/9e3852b9e90b4a19a0ede68dd50b10c7_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/17777/9e3852b9e90b4a19a0ede68dd50b10c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/9e3852b9e90b4a19a0ede68dd50b10c7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/9e3852b9e90b4a19a0ede68dd50b10c7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/9e3852b9e90b4a19a0ede68dd50b10c7_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/9955df29d3de4775afe0390728820062_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":675}} srcSet={"https://cdn.swbpg.com/t/17777/9955df29d3de4775afe0390728820062_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17777/494993dc26e745408bb793d279b4cbc5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/17777/494993dc26e745408bb793d279b4cbc5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17777/494993dc26e745408bb793d279b4cbc5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17777/494993dc26e745408bb793d279b4cbc5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17777/494993dc26e745408bb793d279b4cbc5_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/17777/91c2604b29f746348fa1b23a8b636564_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":578}} srcSet={"https://cdn.swbpg.com/t/17777/91c2604b29f746348fa1b23a8b636564_s=350x_.jpg 350w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gva6130ffzh"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Celoročne&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; min. 1 osoba \tmin. 2noci"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"130 Eur Objekt / noc"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Vianočný pobyt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; min. 1 osoba \tmin. 2 noci "}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"150 Eur Objekt / noc"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Silvester&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; min. 1 osoba \tmin. 2 noci "}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"150 Eur Objekt / noc"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Veľkonočný pobyt &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; min. 1 osoba \tmin. 2 noci "}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"150 Eur Objekt / noc"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Záloha za ubytovanie vopred: "}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"50 % (dohodou) "}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Cena ďalej zahŕňa:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"parkovanie, detská postieľka, drevo do krbu, posteľná bielizeň "}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Preferujeme:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"Rodiny s deťmi "}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Neakceptujeme:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"Domáce zvieratá "}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cjjwpodf3bu"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"email: mamchata.kokavalinia@gmail.com<br>tel. číslo: 0917 093 999<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.facebook.com/MM-Chata-Kokava-L%C3%ADnia-109186834813559"} href={"https://www.facebook.com/MM-Chata-Kokava-L%C3%ADnia-109186834813559"} content={"FACEBOOK"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 19);\">Skontrolovať dostupnosť si môžete cez náš kalendár prepojený z bookingom.&nbsp;<br>https://www.booking.com/hotel/sk/m-amp-m-chata-kokava-linia-soltyska.sk.html?aid=304142&amp;label=gen173nr-1FCAEoggI46AdIM1gEaM0BiAEBmAEiuAEXyAEM2AEB6AEB-AELiAIBqAIDuAKczLKfBsACAdICJDU2YTk5MDljLThkYTgtNDVhMS1hMzk3LTRhMWZlNzJkYTc3NdgCBuACAQ&amp;sid=7363c90c9fc1c0084f82250999345f8c&amp;dist=0;group_adults=2;group_children=0;hapos=5;hpos=5;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=distance_from_search;srepoch=1676453417;srpvid=ee3c42d471ac0360;type=total;ucfs=1&amp;#tab-main</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"gyeg8tk1s7n"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<font color=\"rgba(6,6,6,1)\">Kokava Línia, 985 05 Kokava nad Rimavicou</font>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"10"} place={"kokava linia"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"4wmdkj212gf"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Objednávka<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé meno","type":"text","required":true,"placeholder":"Zadajte celé meno"},{"name":"Kontaktný e-mail","type":"email","required":true,"placeholder":"Zadajte Váš e-mail"},{"name":"Telefónne číslo","type":"text","placeholder":"Telefón +421"},{"name":"Správa","type":"textarea","required":true,"placeholder":"Zadajte text správy"},{"name":"Odoslať","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}